/* Log message to console only if not in Production */
export function _DEBUG (...args) {
    if (process.env.NODE_ENV !== "production") {
    console.debug(...args);
    }
}
  
/* RETURN A HOUR IN THE FORMAT HHhMM */
/* PARAMETER: strHour IN STRING */
/* RETURN: HOUR IN STRING HHhMM */
/* EXAMPLE: getHourFormat("12:00") RETURN "12h00" */
/* CORRECT IF strHour is 00:HH:MM (wrong format from converting Hyperfile datase's hour fields in SQL hour fields) */
export function getHourFormat (strHour) {
    if (strHour && strHour !== "00:00:00") {
        if (strHour.substring (0, 2) === "00") return strHour.substring (3, 5) + "h" + strHour.substring (6, 8)
        else return strHour.substring (0, 2) + "h" + strHour.substring (3, 5);
    }
    else return "";
}

/* RETURN A NUMBER WITH SIGN */
export function getNumberWithSign(input) {
    if (input === 0) {
      return "0"
    }
    const sign = input < 0 ? '-' : '+';
    return `${sign} ${Math.abs(input)}`;
  }

export function strHeight (height) {
  if (height) {
    if (height > 0) {
      const integral = Math.trunc (height / 100);
      const fraction = (height - integral*100);
      const strFraction = fraction.toLocaleString ('fr-FR', {minimumIntegerDigits:2})
      return (`${integral}m${strFraction}`);
    }
  }
  return ('');
}

/* REMOVE ACCENT AND SPECIAL CHARS FROM A STRING */
export function toNormalForm(str) {
    if (str !== null)
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    else return (str);
}

/* RETURN RGB COLOR FROM HEX CODE */
export function decimalToRgb(decimal, bWindevRGB) {
    if (bWindevRGB) 
        return {
            blue: (decimal >> 16) & 0xff,
            green: (decimal >> 8) & 0xff,
            red: decimal & 0xff,
        }
    else return {
      red: (decimal >> 16) & 0xff,
      green: (decimal >> 8) & 0xff,
      blue: decimal & 0xff,
    };
  }

export function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

export function getStrAmount (amount, currency) {
    let strAmount = '';
    let unit = '';
    if (amount !== 0) {
      if (amount === -1) strAmount = 'sans indemnités';
      else {
        let value = Math.abs (amount);
        if (value >= 1000000) {
          value = (value / 1000000);
          unit = 'M';
        }  else if (value >= 1000) {
          value = (value / 1000);
          unit = 'k';
        }
        strAmount = Math.round (value) + ' ' + unit + (currency || '€');
      }
    }
    return strAmount;
}
