import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';
import { theme } from './lib/theme';
import { ErrorBoundary } from './routes/error/error';

import './lib/common.css';

import Menu from './routes/menu/menu';
import Loader from './loader/loader';
import Config from './lib/config';

//Dynamic imports
const RencoStats = React.lazy(() => import ('./routes/rencos/rencoStats'));
const StatsMatchesCards= React.lazy(() => import ('./routes/stats/matches/cards'));
const StatsMatchesCardsReds = React.lazy(() => import ('./routes/stats/matches/cards').then(module => ({ default: module.StatsMatchesCardsReds })));
const StatsMatchesCardsYellows = React.lazy(() => import ('./routes/stats/matches/cards').then(module => ({ default: module.StatsMatchesCardsYellows })));
const StatsMatchesSpectators = React.lazy(() => import ('./routes/stats/matches/spectators'));
const StatsMatchesResults = React.lazy(() => import ('./routes/stats/matches/results'));
const StatsMatchesResultsDefeats = React.lazy(() => import ('./routes/stats/matches/results').then(module => ({ default: module.StatsMatchesResultsDefeats })));
const StatsMatchesResultsProlific = React.lazy(() => import ('./routes/stats/matches/results').then(module => ({ default: module.StatsMatchesResultsProlific })));
const StatsMatchesResultsVictories = React.lazy(() => import ('./routes/stats/matches/results').then(module => ({ default: module.StatsMatchesResultsVictories })));
const StatsMatchesSeriesWon = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesWon })));
const StatsMatchesSeriesUnbeaten = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesUnbeaten })));
const StatsMatchesSeriesDraw = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesDraw })));
const StatsMatchesSeriesLoss = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesLoss })));
const StatsMatchesSeriesWithoutVictory = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesWithoutVictory })));
const StatsMatchesSeriesWithScoring = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesWithScoring })));
const StatsMatchesSeriesWithoutScoring = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesWithoutScoring })));
const StatsMatchesSeriesCleanSheet = React.lazy(() => import ('./routes/stats/matches/series').then(module => ({ default: module.StatsMatchesSeriesCleanSheet })));
const StatsMatchesSeries = React.lazy(() => import ('./routes/stats/matches/series'));
const StatsMatches = React.lazy(() => import ('./routes/stats/matches/statsMatches'));
const RencoResults = React.lazy(() => import ('./routes/rencos/rencoResults'));
const RencoClassement = React.lazy(() => import ('./routes/rencos/rencoClassement'));
const RencoVideo = React.lazy(() => import ('./routes/rencos/rencoVideo'));
const Search = React.lazy(() => import ('./routes/search/search'));
const RencoComment = React.lazy(() => import ('./routes/rencos/comment'));
const Team = React.lazy(() => import ('./routes/home/team'));
const SeasonCompo = React.lazy(() => import ('./routes/home/compo'));
const StatsPlayersBody = React.lazy(() => import ('./routes/stats/players/body'));
const StatsPlayersBodyLarge = React.lazy(() => import ('./routes/stats/players/body').then(module => ({ default: module.StatsPlayersBodyLarge })));
const StatsPlayersBodySmall = React.lazy(() => import ('./routes/stats/players/body').then(module => ({ default: module.StatsPlayersBodySmall })));
const StatsPlayersBodyTall = React.lazy(() => import ('./routes/stats/players/body').then(module => ({ default: module.StatsPlayersBodyTall })));
const StatsPlayersMutations = React.lazy(() => import ('./routes/stats/players/mutations'));
const StatsPlayersMutationsGains = React.lazy(() => import ('./routes/stats/players/mutations').then(module => ({ default: module.StatsPlayersMutationsGains })));
const StatsPlayersMutationsIn = React.lazy(() => import ('./routes/stats/players/mutations').then(module => ({ default: module.StatsPlayersMutationsIn })));
const StatsPlayersMutationsLosses = React.lazy(() => import ('./routes/stats/players/mutations').then(module => ({ default: module.StatsPlayersMutationsLosses })));
const StatsPlayersMutationsOut = React.lazy(() => import ('./routes/stats/players/mutations').then(module => ({ default: module.StatsPlayersMutationsOut })));
const StatsPlayersPerformances = React.lazy(() => import ('./routes/stats/players/performances'));
const StatsPlayersPerformancesAwards = React.lazy(() => import ('./routes/stats/players/performances').then(module => ({ default: module.StatsPlayersPerformancesAwards })));
const StatsPlayersPerformancesResultsWon = React.lazy(() => import ('./routes/stats/players/performances').then(module => ({ default: module.StatsPlayersPerformancesResultsWon })));
const StatsPlayersPerformancesResultsDefeats = React.lazy(() => import ('./routes/stats/players/performances').then(module => ({ default: module.StatsPlayersPerformancesResultsDefeats })));
const StatsPlayersPerformancesResultsDraws = React.lazy(() => import ('./routes/stats/players/performances').then(module => ({ default: module.StatsPlayersPerformancesResultsDraws })));
const StatsPlayersReds = React.lazy(() => import ('./routes/stats/players/reds'));
const StatsPlayersRedsBySeasons = React.lazy(() => import ('./routes/stats/players/reds').then(module => ({ default: module.StatsPlayersRedsBySeasons })));
const StatsPlayersRedsFastest = React.lazy(() => import ('./routes/stats/players/reds').then(module => ({ default: module.StatsPlayersRedsFastest })));
const StatsPlayersRedsGlobal = React.lazy(() => import ('./routes/stats/players/reds').then(module => ({ default: module.StatsPlayersRedsGlobal })));
const StatsPlayersYellows = React.lazy(() => import ('./routes/stats/players/yellows'));
const StatsPlayersYellowsBySeasons = React.lazy(() => import ('./routes/stats/players/yellows').then(module => ({ default: module.StatsPlayersYellowsBySeasons })));
const StatsPlayersYellowsFastest = React.lazy(() => import ('./routes/stats/players/yellows').then(module => ({ default: module.StatsPlayersYellowsFastest })));
const StatsPlayersYellowsGlobal = React.lazy(() => import ('./routes/stats/players/yellows').then(module => ({ default: module.StatsPlayersYellowsGlobal })));
const StatsPlayersAssists = React.lazy(() => import ('./routes/stats/players/assists'));
const StatsPlayersAssistsByMatches = React.lazy(() => import ('./routes/stats/players/assists').then(module => ({ default: module.StatsPlayersAssistsByMatches })));
const StatsPlayersAssistsBySeasons = React.lazy(() => import ('./routes/stats/players/assists').then(module => ({ default: module.StatsPlayersAssistsBySeasons })));
const StatsPlayersAssistsFastest = React.lazy(() => import ('./routes/stats/players/assists').then(module => ({ default: module.StatsPlayersAssistsFastest })));
const StatsPlayersAssistsGlobal = React.lazy(() => import ('./routes/stats/players/assists').then(module => ({ default: module.StatsPlayersAssistsGlobal })));
const StatsPlayersAssistsRatio = React.lazy(() => import ('./routes/stats/players/assists').then(module => ({ default: module.StatsPlayersAssistsRatio })));
const StatsPlayersAssistsSeries = React.lazy(() => import ('./routes/stats/players/series').then(module => ({ default: module.StatsPlayersAssistsSeries })));
const StatsPlayersScorers = React.lazy(() => import ('./routes/stats/players/scorers'));
const StatsPlayersScorersByMatches = React.lazy(() => import ('./routes/stats/players/scorers').then(module => ({ default: module.StatsPlayersScorersByMatches })));
const StatsPlayersScorersBySeasons = React.lazy(() => import ('./routes/stats/players/scorers').then(module => ({ default: module.StatsPlayersScorersBySeasons })));
const StatsPlayersScorersFastest = React.lazy(() => import ('./routes/stats/players/scorers').then(module => ({ default: module.StatsPlayersScorersFastest })));
const StatsPlayersScorersGlobal = React.lazy(() => import ('./routes/stats/players/scorers').then(module => ({ default: module.StatsPlayersScorersGlobal })));
const StatsPlayersScorersRatio = React.lazy(() => import ('./routes/stats/players/scorers').then(module => ({ default: module.StatsPlayersScorersRatio })));
const StatsPlayersScorersSeries = React.lazy(() => import ('./routes/stats/players/series').then(module => ({ default: module.StatsPlayersScorersSeries })));
const StatsPlayersAppearances = React.lazy(() => import ('./routes/stats/players/appearances'));
const StatsPlayersAppearancesBySeasons = React.lazy(() => import ('./routes/stats/players/appearances').then(module => ({ default: module.StatsPlayersAppearancesBySeasons })));
const StatsPlayersAppearancesGlobal = React.lazy(() => import ('./routes/stats/players/appearances').then(module => ({ default: module.StatsPlayersAppearancesGlobal })));
const StatsPlayersAppearancesYears = React.lazy(() => import ('./routes/stats/players/appearances').then(module => ({ default: module.StatsPlayersAppearancesYears })));
const StatsPlayersAppearancesFirst = React.lazy(() => import ('./routes/stats/players/appearance').then(module => ({ default: module.StatsPlayersAppearancesFirst })));
const StatsPlayersAppearancesLast = React.lazy(() => import ('./routes/stats/players/appearance').then(module => ({ default: module.StatsPlayersAppearancesLast })));
const StatsPlayers = React.lazy(() => import ('./routes/stats/players/statsPlayers'));
const ClubHistory = React.lazy(() => import ('./routes/clubs/clubHistory'));
const PlayerContracts = React.lazy(() => import ('./routes/players/playerContract'));
const PlayerStats = React.lazy(() => import ('./routes/players/playerStats'));
const HomeStats = React.lazy(() => import ('./routes/home/home-statistics'));
const HomeStatsSubMenu = React.lazy(() => import ('./routes/home/home-statistics').then(module => ({ default: module.HomeStatsSubMenu })));
const HomePalmares = React.lazy(() => import ('./routes/home/palmares'));
const TourRencos = React.lazy(() => import ('./routes/tours/tourRencos'));
const PlayerRencos = React.lazy(() => import ('./routes/players/playerRencos'));
const ClubRencos = React.lazy(() => import ('./routes/clubs/clubRencos'));
const Players = React.lazy(() => import ('./routes/players/playersSeasons'));
const Player = React.lazy(() => import ('./routes/players/player'));
const PlayerSeasons = React.lazy(() => import ('./routes/players/playerSeasons'));
const PlayerSummary = React.lazy(() => import ('./routes/players/playerSummary'));
const Classement = React.lazy(() => import ('./routes/tours/classement'));
const Tours = React.lazy(() => import ('./routes/compets/tours'));
const Tour = React.lazy(() => import ('./routes/tours/tour'));
const CompetPalmares = React.lazy(() => import ('./routes/compets/palmares'));
const Compet = React.lazy(() => import ('./routes/compets/compet'));
const ClubPalmares = React.lazy(() => import ('./routes/clubs/clubPalmares'));
const Club = React.lazy(() => import ('./routes/clubs/club'));
const Compo = React.lazy(() => import ('./routes/rencos/compo'));
const Events = React.lazy(() => import ('./routes/rencos/events'));
const Renco = React.lazy(() => import ('./routes/rencos/renco'));
const Rencos = React.lazy(() => import ('./routes/rencos/rencos'));
const ComingSoon = React.lazy(() => import ('./lib/comming-soon'));
const Param = React.lazy(() => import ('./routes/param/param'));
const Error = React.lazy(() => import ('./routes/error/error'));
const Welcome = React.lazy(() => import ('./routes/home/welcome'));
const Home = React.lazy(() => import ('./routes/home/home'));
const Calendar = React.lazy(() => import ('./routes/home/calendar'));

function Wait (props) {
  return (
    <Suspense fallback={<Loader/>} >
      {props.children}
    </Suspense>
  );
}

export default function App () {
    return (
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <Config>
              <Routes>
                <Route path="/" element={<Menu />}>
                  <Route index                element={<Wait><Home init={true}/></Wait>} />
                  <Route path="home"          element={<Wait><Home /></Wait>}>
                    <Route index              element={<Wait><Welcome /></Wait>}/>
                    <Route path="welcome"     element={<Wait><Welcome /></Wait>} />  
                    <Route path="calendar"    element={<Wait><Calendar /></Wait>} />
                    <Route path="palmares"    element={<Wait><HomePalmares /></Wait>}/>
                    <Route path="team"        element={<Wait><Team/></Wait>} />
                    <Route path="seasonteam"  element={<Wait><SeasonCompo/></Wait>} />
                    <Route path="stats"         element={<Wait><HomeStatsSubMenu/></Wait>}>
                      <Route index              element={<Wait><HomeStats stat="appearances" /></Wait>} />
                      <Route path="appearances" element={<Wait><HomeStats stat="appearances" /></Wait>} />
                      <Route path="scorers"     element={<Wait><HomeStats stat="scorers" /></Wait>} />
                      <Route path="assists"     element={<Wait><HomeStats stat="assists" /></Wait>} />
                      <Route path="cards"       element={<Wait><HomeStats stat="cards" /></Wait>} />
                    </Route>
                  </Route>
                  <Route path="search"  element={<Wait><Search/></Wait>} />
                  <Route path="rencos"  element={<Wait><Rencos /></Wait>} />
                  <Route path="rencos/:id"                  element={<Wait><Renco /></Wait>} >
                    <Route path="events"                    element={<Wait><Events /></Wait>} />
                    <Route path="compo"                     element={<Wait><Compo /></Wait>} />
                    <Route path="comment"                   element={<Wait><RencoComment /></Wait>} />
                    <Route path="video"                     element={<Wait><RencoVideo /></Wait>} />
                    <Route path='tours/:tourid/classement'  element={<Wait><RencoClassement /></Wait>} />
                    <Route path='tours/:tourid/rencos'      element={<Wait><RencoResults /></Wait>} />
                    <Route path='stats'                     element={<Wait><RencoStats /></Wait>} />
                  </Route>
                  <Route path="compets/:id"   element={<Wait><Compet /></Wait>} >
                      <Route path="palmares"  element={<Wait><CompetPalmares/></Wait>} />
                      <Route path="tours"     element={<Wait><Tours/></Wait>} />
                  </Route>
                  <Route path="tours/:tourid" element={<Wait><Tour /></Wait>} >
                    <Route path="rencos"      element={<Wait><TourRencos/></Wait>} />
                    <Route path="classement"  element={<Wait><Classement/></Wait>} />
                  </Route>
                  <Route path="clubs/:id"   element={<Wait><Club /></Wait>} >
                    <Route path="palmares"  element={<Wait><ClubPalmares/></Wait>} />
                    <Route path="history"   element={<Wait><ClubHistory/></Wait>} />
                    <Route path="rencos"    element={<Wait><ClubRencos/></Wait>} />
                  </Route>
                  <Route path="players"     element={<Wait><Players /></Wait>} />
                  <Route path="players/:id" element={<Wait><Player /></Wait>} >
                    <Route path="seasons"   element={<Wait><PlayerSeasons /></Wait>} />
                    <Route path="summary"   element={<Wait><PlayerSummary /></Wait>} />
                    <Route path="rencos"    element={<Wait><PlayerRencos /></Wait>} />
                    <Route path='palmares'  element={<Wait><ComingSoon/></Wait>}/> 
                    <Route path='contracts' element={<Wait><PlayerContracts/></Wait>}/> 
                    <Route path='stats'     element={<Wait><PlayerStats/></Wait>}/> 
                  </Route>
                  <Route path="stats/players" element={<Wait><StatsPlayers /></Wait>} >
                    <Route index              element={<Wait><StatsPlayersAppearances /></Wait>} />
                    <Route path="appearances"       element={<Wait><StatsPlayersAppearances /></Wait>} >
                      <Route index                  element={<Wait><StatsPlayersAppearancesGlobal /></Wait>} />
                      <Route path='seasons'         element={<Wait><StatsPlayersAppearancesBySeasons /></Wait>} />
                      <Route path='years'           element={<Wait><StatsPlayersAppearancesYears /></Wait>} />
                      <Route path='first'           element={<Wait><StatsPlayersAppearancesFirst /></Wait>} />
                      <Route path='last'            element={<Wait><StatsPlayersAppearancesLast /></Wait>} />
                    </Route>
                    <Route path='scorers'           element={<Wait><StatsPlayersScorers /></Wait>} >
                      <Route index                  element={<Wait><StatsPlayersScorersGlobal /></Wait>} />
                      <Route path='seasons'         element={<Wait><StatsPlayersScorersBySeasons /></Wait>} />
                      <Route path='matches'         element={<Wait><StatsPlayersScorersByMatches /></Wait>} />
                      <Route path='ratio'           element={<Wait><StatsPlayersScorersRatio /></Wait>} />
                      <Route path='fastest'         element={<Wait><StatsPlayersScorersFastest /></Wait>} />
                      <Route path='series'          element={<Wait><StatsPlayersScorersSeries /></Wait>} />
                    </Route>
                    <Route path="assists"           element={<Wait><StatsPlayersAssists /></Wait>} >
                      <Route index                  element={<Wait><StatsPlayersAssistsGlobal /></Wait>} />
                      <Route path='seasons'         element={<Wait><StatsPlayersAssistsBySeasons /></Wait>} />
                      <Route path='matches'         element={<Wait><StatsPlayersAssistsByMatches /></Wait>} />
                      <Route path='ratio'           element={<Wait><StatsPlayersAssistsRatio /></Wait>} />
                      <Route path='fastest'         element={<Wait><StatsPlayersAssistsFastest /></Wait>} />
                      <Route path='series'          element={<Wait><StatsPlayersAssistsSeries /></Wait>} />
                    </Route>
                    <Route path="yellows"           element={<Wait><StatsPlayersYellows /></Wait>} >
                      <Route index                  element={<Wait><StatsPlayersYellowsGlobal /></Wait>} />
                      <Route path='seasons'         element={<Wait><StatsPlayersYellowsBySeasons /></Wait>} />
                      <Route path='fastest'         element={<Wait><StatsPlayersYellowsFastest /></Wait>} />
                    </Route>
                    <Route path="reds"              element={<Wait><StatsPlayersReds /></Wait>} >
                      <Route index                  element={<Wait><StatsPlayersRedsGlobal /></Wait>} />
                      <Route path='seasons'         element={<Wait><StatsPlayersRedsBySeasons /></Wait>} />
                      <Route path='fastest'         element={<Wait><StatsPlayersRedsFastest /></Wait>} />
                    </Route>
                    <Route path="performances"    element={<Wait><StatsPlayersPerformances /></Wait>} >
                      <Route index                element={<Wait><StatsPlayersPerformancesAwards /></Wait>} />
                      <Route path='awards'        element={<Wait><StatsPlayersPerformancesAwards /></Wait>} />
                      <Route path='won'           element={<Wait><StatsPlayersPerformancesResultsWon /></Wait>} />
                      <Route path='defeats'       element={<Wait><StatsPlayersPerformancesResultsDraws /></Wait>} />
                      <Route path='draws'         element={<Wait><StatsPlayersPerformancesResultsDefeats /></Wait>} />
                    </Route>
                    <Route path="mutations"   element={<Wait><StatsPlayersMutations /></Wait>} >
                      <Route index            element={<Wait><StatsPlayersMutationsIn /></Wait>} />
                      <Route path='in'        element={<Wait><StatsPlayersMutationsIn /></Wait>} />
                      <Route path='out'       element={<Wait><StatsPlayersMutationsOut /></Wait>} />
                      <Route path='gains'     element={<Wait><StatsPlayersMutationsGains /></Wait>} />
                      <Route path='losses'    element={<Wait><StatsPlayersMutationsLosses /></Wait>} />
                    </Route>
                    <Route path="body"      element={<Wait><StatsPlayersBody /></Wait>} >
                      <Route index          element={<Wait><StatsPlayersBodyTall/></Wait>} />
                      <Route path='tall'    element={<Wait><StatsPlayersBodyTall /></Wait>} />
                      <Route path='small'   element={<Wait><StatsPlayersBodySmall /></Wait>} />
                      <Route path='large'   element={<Wait><StatsPlayersBodyLarge /></Wait>} />
                    </Route>
                  </Route>  
                  <Route path="stats/matches" element={<Wait><StatsMatches /></Wait>} >
                    <Route index              element={<Wait><StatsMatchesResults /></Wait>} />
                    <Route path="results"     element={<Wait><StatsMatchesResults /></Wait>} >
                      <Route index            element={<Wait><StatsMatchesResultsVictories /></Wait>} />
                      <Route path='victories' element={<Wait><StatsMatchesResultsVictories /></Wait>} />
                      <Route path='defeats'   element={<Wait><StatsMatchesResultsDefeats /></Wait>} />
                      <Route path='prolific'  element={<Wait><StatsMatchesResultsProlific /></Wait>} />
                    </Route>
                    <Route path="spectators"  element={<Wait><StatsMatchesSpectators /></Wait>} />
                    <Route path="cards"       element={<Wait><StatsMatchesCards /></Wait>} >
                      <Route index            element={<Wait><StatsMatchesCardsYellows /></Wait>} />
                      <Route path='yellows'   element={<Wait><StatsMatchesCardsYellows/></Wait>} />
                      <Route path='reds'      element={<Wait><StatsMatchesCardsReds/></Wait>} />
                    </Route>
                    <Route path="series"  element={<Wait><StatsMatchesSeries /></Wait>} >
                      <Route index                  element={<Wait><StatsMatchesSeriesWon /></Wait>} />
                      <Route path='won'             element={<Wait><StatsMatchesSeriesWon/></Wait>} />
                      <Route path='unbeaten'        element={<Wait><StatsMatchesSeriesUnbeaten/></Wait>} />
                      <Route path='draw'            element={<Wait><StatsMatchesSeriesDraw/></Wait>} />
                      <Route path='loss'            element={<Wait><StatsMatchesSeriesLoss/></Wait>} />
                      <Route path='without-victory' element={<Wait><StatsMatchesSeriesWithoutVictory/></Wait>} />
                      <Route path='with-scoring'    element={<Wait><StatsMatchesSeriesWithScoring/></Wait>} />
                      <Route path='without-scoring' element={<Wait><StatsMatchesSeriesWithoutScoring/></Wait>} />
                      <Route path='clean-sheet'     element={<Wait><StatsMatchesSeriesCleanSheet/></Wait>} />
                    </Route>
                  </Route>
                  <Route path="param" element={<Wait><Param /></Wait>} />  
                  <Route path="*" element={<Wait><Error error={404} errorInfo={'page non trouvée'} /></Wait>} />
                </Route>
              </Routes>
              </Config>
            </ErrorBoundary>
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    );
};
