import { Helmet } from 'react-helmet-async';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Card, CardContent, Typography, Stack, Box, IconButton, Avatar, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { theme } from './theme';
import useFetch from './fetchAPI';
import { _STORAGE_KEY_IS_IMAGE } from './constants';
import { _CONFIG } from './config';

const StyledBox = styled(Box)(() => ({
    background: theme.palette.background.title,
    color: theme.palette.title.main,
    position: 'relative',
    padding: '5px',
    width:'max-content',
    minWidth:'50%',
    display: 'inline-block',
      '&::before': {
        content: '""',
        background: theme.palette.background.title,
        position: 'absolute',
        top:0,
        left:'-12px',
        right:'-12px',
        bottom:0,
        zindex: -1,
        transform: 'skewX(35deg)',
      },
}));

export function Title (props) {
  const {title, Component} = props;
  return (
    <StyledBox>
      <Stack direction={'row'} spacing={1}>
        <Typography sx={{position:'relative'}} variant='h1'>
          {title}
        </Typography>
        {(Component !== undefined) && <>{Component}</>}
      </Stack>
    </StyledBox>
  );
}

export function ItemTitle (props) {
  return (
    <Typography variant='itemTitle' align='center' margin={1} marginBottom={1}>
      {props.title}
    </Typography>
  );
}

export function Content (props) {
  return (
    <Stack
      sx={{margin:1, marginBottom:5, padding:0}}
      {...props}
    >
      {props.children}
    </Stack>
  );
}

export function Text (props) {
  const text = props.text;
  return (
    <Typography {...props} variant='bodyText' paragraph={true} align='justify'>
      {text}
    </Typography>
  );
}

export function CustomCard (props) {
  const {title, titleComponent, to, filterClick, ...rest} = props;
  return (
    <Card
      variant='outlined' 
      sx={{
        marginBottom:1, 
        padding:"0px", 
        minWidth: 275, 
        boxShadow: 5, 
        borderRadius: 2, 
        borderColor:'#dc0000',
        backgroundColor: 'background.card'
      }}
      {...rest}
    >
      {(props.title) && 
        <Stack direction={'row'} spacing={2}>
          <Title title={title} Component={titleComponent} /> 
          {(to) &&
            <IconButton component={RouterLink} to={to} size='small' color='primary'>
              <LoupeOutlinedIcon />
            </IconButton>
          } 
          {(filterClick) &&
            <IconButton size='small' color='primary' onClick={filterClick}>
              <FilterAltOutlinedIcon />
            </IconButton>
          }
        </Stack>
      }
        {props.children}
    </Card>
  );
}

export function CustomTextCard (props) {
  const title = props.title
  const contents = props.contents
  return (
    <CustomCard title={title} >
      <CardContent>
        {(contents) && contents.map((content, key) =>
          <Typography key={key} variant='bodyText' component='div'>
              {content}
          </Typography>
        )}
      </CardContent>
    </CustomCard>
  )
}

export function CustomImage (props) {
  const isImageEnabled = JSON.parse(localStorage.getItem(_STORAGE_KEY_IS_IMAGE)) ?? true;
  const [data] = useFetch ((isImageEnabled) ? props.api : null, "GET");
  const [image, setImage] = React.useState (null);
  let avatarSize = 24;

  React.useEffect (() => {
    if (data) {
      if (data) setImage (data.image)
      else setImage (null);
    }
  }, [data])

  switch (props.variant) {
    case 'avatar-large': 
      avatarSize = 48;
    // eslint-disable-next-line no-fallthrough
    case 'avatar':
      if (!image) 
        return (
          <Avatar sx={{ width: avatarSize, height: avatarSize }} >
            <SvgIcon size={'large'} color={'action'} >{props.defaultImage}</SvgIcon>
          </Avatar>
        )
      else return (
        <Avatar
          sx={{ width: avatarSize, height: avatarSize }}
          src={'data:image/png;base64,'+ image}
          alt={props.alt}
        />
      );
    case 'small' :
      if (!image)
        return (<SvgIcon size={'large'} color={'action'} >{props.defaultImage}</SvgIcon>)
      else return (
        <img 
          style={{maxWidth:"40px", height: '30px'}}
          alt={props.alt} 
          src={'data:image/png;base64,'+ image}
        />
      );
    case 'standard' :
      if (!image)
        return (<SvgIcon sx={{fontSize: 50}} color={'action'} >{props.defaultImage}</SvgIcon>)
      return (
        <img 
          style={{verticalAlign: "middle", maxWidth:'60px', height:'45px' }}
          alt={props.alt} 
          src={'data:image/png;base64,'+ image}
        />
      );
    case 'large' :
    default:
      if (!image)
        return (<SvgIcon sx={{fontSize: 100}} color={'action'} >{props.defaultImage}</SvgIcon>)
      return (
        <img 
          style={{verticalAlign: "middle", maxWidth:'120px', height:'90px' }}
          alt={props.alt} 
          src={'data:image/png;base64,'+ image}
        />
      );
  }
}

export function strNatio (natio) {
  if (natio && natio !== null && natio !== _CONFIG.country) return ' (' + natio + ')'
  else return '';
}

export function renderMetaTag (metaTitle, metaDescription, metaKeywords, bNoIndex) {
  if (bNoIndex) 
    return (
      <Helmet>
          <meta name="robots" content="noindex"/>
          <meta name="googlebot" content="noindex"/>
      </Helmet>
    )
  else return (
      <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="robots" content="all"/>
          <meta name="googlebot" content="all"/>
      </Helmet>
  );
}

export class CustomDataGrid extends React.Component {
  render() {
    const pageSize = this.props.pageSize || 25;
    return (
        <DataGrid
            {...this.props}
            autoHeight={true}
            pageSize={pageSize}
            hideFooter={(pageSize > this.props.rows.length)}
            disableColumnMenu={true}
            disableColumnFilter={true}
            size={'small'}
            density={'compact'}
            />
        );
    }
}