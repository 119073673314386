import React from 'react';
import { CardContent } from '@mui/material';
import { Stack } from '@mui/system';
import { Content, CustomCard } from '../../lib/common';
import { renderMetaTag } from '../../lib/common';
import Menu from '../menu/menu';

export default function Error (props) {
  const metagTags = renderMetaTag (null, null, null, true);

    return (
        <Content> {metagTags}
            <CustomCard title = 'Une erreur est survenue'>
              <CardContent>
                <details>
                    Code erreur : {props.error && props.error.toString()}
                    <br/>
                    Message : {props.errorInfo}
                </details>
                <button onClick={() => window.location.href='/'}>
                  Relancer
                </button>
              </CardContent>
            </CustomCard>
        </Content>
    );
  }

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <Stack>
            <Menu/>
            <Error
                error={this.state.error}
                errorInfo={this.state.errorInfo.componentStack}
            />
          </Stack>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }
  