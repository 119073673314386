import React from 'react';

// **********************************************
// function : useSessionStorage
//  storageKey : key to get back the stored content
//  defaultValue : default content to get if no content is stored
// description : React hook to set and get content in session storage
// **********************************************
export function useSessionStorage (storageKey, defaultValue) {
    const [value, setValue] = React.useState (JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultValue
    );
    React.useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(value));
      }, [value, storageKey]);
    
      return [value, setValue];
}

// **********************************************
// function : useLocalStorage
//  storageKey : key to get back the stored content
//  defaultValue : default content to get if no content is stored
// description : React hook to set and get content in local storage
// **********************************************
export function useLocalStorage (storageKey, defaultValue) {
    const [value, setValue] = React.useState (JSON.parse(localStorage.getItem(storageKey)) ?? defaultValue
    );
    React.useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
      }, [value, storageKey]);
    
      return [value, setValue];
}

export function getLocalStorage (storageKey, defaultValue) {
  const value = JSON.parse(localStorage.getItem(storageKey)) ?? defaultValue;
  return value;
}

