import React from 'react';
import { Link as RouterLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import { AppBar, Box, IconButton, SvgIcon } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Stack from '@mui/system/Stack';
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as StatsPlayersLogo } from "./statsPlayers.svg";
import { ReactComponent as StatsMatchesLogo } from "./statsMatches.svg";

const buttons = [
  {index:0, to:'', icon:ArrowCircleLeftOutlinedIcon},
  {index:1, to:`/rencos`, icon:CalendarMonthOutlinedIcon},
  {index:2, to:'/search', icon:SearchOutlinedIcon},
  {index:3, to:'/home',},
  {index:4, to:'/stats/players/appearances', path:'/stats/players', icon:StatsPlayersLogo},
  {index:5, to:'/stats/matches/results', path:'/stats/matches', icon:StatsMatchesLogo},
  {index:6, to:'/param', icon:SettingsOutlinedIcon},
];

function Item (props) {
  const navigate = useNavigate();

  let style= {};
  if (props.selected) {
    style = {
      borderBottom: "2px solid white",
      marginBottom: '2px',
    }
  }
  else {
    style = {
      marginBottom: '2px',
    }
  }
  
  return (
    <Box sx={style} component={Stack} direction={'column'} justifyContent={'center'}>
      {
        <IconButton 
          color={'inherit'} 
          component={(!props.back) ? RouterLink : undefined}
          to={(!props.back) ? buttons[props.index].to : undefined}
          onClick={() => {if (props.back) navigate(-1)}}
        >
          {
            (props.home)
              ? <Logo fill='white' height="60px" />
              : <SvgIcon component={buttons[props.index].icon} inheritViewBox fontSize='large' />
          }
        </IconButton>
      }
    </Box>
  );
}

export default function  Menu () {
  const location = useLocation();
  const [itemSelected, setItemSelected] = React.useState (0);

  React.useEffect (() => {
    let index = 0;
    for (let i = 0; i < buttons.length; i++) {
      const element = buttons[i];
      if (location.pathname.indexOf(element.path || element.to) > -1) {
        index = (i);
      }
    }
    setItemSelected (index);
  }, [location]);

  return (
    <>
      <AppBar position='sticky'>
        <Stack direction={'row'} width={'100%'} justifyContent={'space-between'}>
          <Stack direction={'row'} width={'100%'} justifyContent={'space-around'}>
            <Item selected={false} index={0} back/>
            <Item selected={(itemSelected===1)} index={1}/>
            <Item selected={(itemSelected===2)} index={2}/>
          </Stack>
          <Stack>
            <Item selected={(itemSelected===3)} index={3} home/>
          </Stack>
          <Stack direction={'row'} width={'100%'} justifyContent={'space-around'}>
            <Item selected={(itemSelected===4)} index={4}/>
            <Item selected={(itemSelected===5)} index={5}/>
            <Item selected={(itemSelected===6)} index={6}/>
          </Stack>
        </Stack>
      </AppBar>
      <Outlet/>
    </>
  );
}

  