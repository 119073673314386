import { useEffect } from "react";
import useFetch from "./fetchAPI";
import { useLocalStorage } from "./localStorage";
import Loader from "../loader/loader";
import { Stack } from "@mui/system";
import {_STORAGE_KEY_CONFIG} from "./constants";

export let _CONFIG = null;

export default function Config (props) {
    const [configLocal, setConfigLocal] = useLocalStorage (_STORAGE_KEY_CONFIG, null);
    const [configDB, isLoading] = useFetch (`/config`, "GET");

    useEffect(() => {
        if (!_CONFIG) {
            _CONFIG = configLocal;
        }
    }, [configLocal]);

    useEffect(() => {
        if (!isLoading) {
            if (configDB) {
                setConfigLocal (configDB);
                _CONFIG = configDB;
            }
        }
    }, [isLoading, configDB, setConfigLocal]);

    if (!_CONFIG) return (<Loader/>)
    else return (
        <Stack {...props} >
            {props.children}
        </Stack>
      );
}