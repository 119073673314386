import React from 'react';
import { _DEBUG } from './tools';
import {_DEFAULT_PAGE_SIZE, _STORAGE_KEY_IS_CACHE} from './constants';

// **********************************************
// function : useFetch
//  url : URL to call API
//  method : method to call API's resources (GET, POST, PUT, DELETE)
//  bWithPagination (optional) : true if data should be fetched page by page
// description : to use as a React hook 
// **********************************************
export default function useFetch (url, method, bWithPagination) {
    const [data, setData] = React.useState (null); 
    const [isLoading, setIsLoading] = React.useState (true); 
    const [isFetching, setIsFetching] = React.useState (true);

    const bWithCache = JSON.parse(localStorage.getItem(_STORAGE_KEY_IS_CACHE)) ?? true;

    const [dataPage, setDataPage] = React.useState(1);
    const dataPageSize = _DEFAULT_PAGE_SIZE;

    //set ifFetching if url is different from the previous one
    React.useEffect(() => {
        setIsFetching (true);
        setIsLoading(true);
        setDataPage (1);
        setData (null);
    }, [url]);

    const updateData =  (data, newData, bInit) => {
        if (bInit || !data) return newData;
        else {
            let oldData = data;
            oldData = [...oldData, ...newData];
            return oldData;    
        };
    };

    React.useEffect(() => {
        if (url && isFetching) {
                let paramPagination = (bWithPagination) 
                    ? "&page=" + dataPage + "&pageSize=" + dataPageSize
                    : '';
                if (url.indexOf ('?') === -1) {
                    paramPagination = '?' + paramPagination;
                }
                callAPISupporter(url + paramPagination, method, null, bWithCache, true)
                .then ((results) => {
                    const rows = results.response.data;
                    const bInit = (dataPage === 1);
                    setData (data => updateData (data, rows, bInit));
                    setIsLoading(false);
                    if (!bWithPagination) setIsFetching (false);
                    else {
                        //on complète data avec response
                        if (rows.length === 0) {
                            //response est vide, on stopper le fetching et on set data à vide
                            if (dataPage === 1) {
                                setIsFetching(false);
                            }
                        }
                        if (rows.length < dataPageSize) {
                            //response n'est ni vide ni plein => On stoppe le fetching
                            if (dataPage !== 1) {
                                setDataPage (1);
                                setIsFetching (false);
                            }
                        }
                        if (rows.length === dataPageSize) {
                            //response est plein => On fetche la page suivante
                            setDataPage(dataPage + 1);
                        };
                    }
                }
                , (error) => {
                    setData(null);
                    setIsLoading(false);
                    setIsFetching (false);
                });
        }    
    }, [url, method, bWithPagination, bWithCache, isFetching, dataPage, dataPageSize]);

    return [data, isLoading, isFetching];
}

export function callAPISupporter (strPath, strMethod, strPayLoad, bWithCache, bRetry) {
    const url = process.env.REACT_APP_API_URL + strPath;
    return (callAPI (url, strMethod, strPayLoad, bWithCache, bRetry));
}

/* RETURN AJAX API CALL */
function callAPI(strPath, strMethod, strPayLoad, bWithCache, bRetry) {
    return new Promise((resolve, reject) => {
        let cachedResult = null;
        if (bWithCache) cachedResult = JSON.parse(sessionStorage.getItem(strPath));
        if (cachedResult && bWithCache) {
            resolve(cachedResult);
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open(strMethod, strPath);
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.responseType = "json";
            xhr.onload = function() {
                if (this.status === 200) {
                    _DEBUG ("received : ", this.response);
                    if (bWithCache)
                        try {
                            sessionStorage.setItem (strPath, JSON.stringify(this.response));
                        } catch (e) {
                            if (e.name === 'QuotaExceededError') {
                                sessionStorage.clear();
                            }
                        };
                    resolve(this.response);
                } else {
                    console.error ('received : ', `ERROR ${this.status}`, JSON.stringify(this.response));
                    reject({
                        status: this.status,
                        error: JSON.stringify(this.response),
                    });
                }
            };
            xhr.onerror = function(error) {
                console.error (`received : ERROR ${this.status} on request ${strMethod} ${strPath}`, error);
                if (bRetry) {
                    console.error ('retry:', `${strMethod} ${strPath}`);
                    return (callAPI(strPath, strMethod, strPayLoad, bWithCache, false));
                } else reject({
                    status: this.status,
                    error: error
                });
            };
            xhr.ontimeout = function(error) {
                console.error (`received : TIMEOUT ${this.status} on request ${strMethod} ${strPath}`, error);
                reject({
                    status: 408,
                    error: error
                });
            };
            xhr.send(strPayLoad);       
            _DEBUG ("sent : ", `${strMethod} ${strPath}`); 
        }
    });
};
