import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    background: {
      card: 'snow',
      subMenu: 'whitesmoke',
      menu:'#dc0000',
      title:'#dc0000',
      eventChips:'whitesmoke',
    },
    title:{
      main: 'white',
    },
    primary: {
      main: '#dc0000',
    },
    secondary: {
      main: '#795548',
    },
  },
  typography:{
    event:{
      fontSize:14,
    },
    h1:{
      fontSize: 18,
      fontWeight:'bold',
    },
    h2:{
      fontSize: 16,
      fontWeight:'bold',
    },
    score:{
      fontSize:40,
      fontWeight:'bold',
    },
    itemTitle:{
      fontSize:30,
      fontWeight:'bold',
    },
    itemSubTitle:{
      fontSize:20,
      fontWeight:'bold',
    },
    bodyText:{
      fontSize:14,
      fontWeight:'standard',
    }
  }
});
